<template>
  <div>
    <el-image v-if="banner" class="w100" style="display: block;" :src="banner"></el-image>

    <div class="w100 technical-tab">
      <ul>
        <!--<li :class="{'active': type == item.value}" v-for="(item, i) in types" :underline="false" @click="changeType(item.value)">{{item[lang + 'name']}}</li>-->
        <li class="active">{{$t('page.console.menu.technical_support')}}</li>
      </ul>
    </div>

    <div class="container mt20 mb20"  v-loading="loading">
      <h2 style="text-align: center; margin: 10px auto; font-size: 24px;">{{data[lang + 'title']}}</h2>
      <div v-html="data[lang + 'content']"></div>
    </div>

    <!--<div class="container mt20" v-loading="loading">

      <div class="team-box"  v-if="type == 'researchItem'">
        <div class="research-item" v-for="(item, i) in list" :key="`technical` + item.id" @click="showTeam(item)">
          <div class="mess">
            <p class="label">{{item[lang + 'title']}}</p>
            <div class="job-mess">
              <p v-html="item[lang + 'desc']"></p>
            </div>
          </div>
          <div class="pic">
            <img :src="item.thumbnail" alt="img">
            <i class="block"></i>
          </div>
        </div>
      </div>

      <ul class="technical-warp" v-else>
        <li v-for="(item, i) in list" :key="`technical` + item.id">
          <div class="technical-item" @click="goto(item.id)">
            <el-image :src="item.thumbnail"></el-image>

            <div class="technical-item-right">
              <div class="technical-item-content">
                <h3 class="font-rem-16">{{item[lang + 'title']}}</h3>

                <p class="desc font-rem-12">{{item[lang + 'desc']}}</p>

                <span class="time">{{item.create_time}}</span>
              </div>
            </div>
          </div>
        </li>
      </ul>

      <el-pagination
          class="mb20 line"
          background
          :hide-on-single-page="onePage"
          :total="total"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :page-sizes="pageSizes"
          @size-change="get"
          @current-change="get"
          layout="prev, pager, next">
      </el-pagination>

      <el-dialog
          :visible.sync="teamVisible"
          class="team-pop">
        <div class="pop-box" v-if="teamData">
          <div class="team-pic">
            <img :src="teamData.thumbnail" alt="img">
          </div>
          <div class="mess-box">
            <div class="t-box">
              <p class="name">{{teamData.title}}</p>
              <div class="ms" v-html="teamData.desc"></div>
            </div>
            <div class="desc" v-html="teamData.content">

            </div>
          </div>
        </div>
      </el-dialog>
    </div>-->
  </div>
</template>

<script>
import config from '@/config';
import {getPosts, getOption} from "@/api/os/admin";

export default {
  data() {
    return {
      optionName: 'page-banner',
      type: config.default.technicalType[0].value,
      types: config.default.technicalType,
      currentPage: 0,
      pageSize: config.default.pageLimit,
      pageSizes: config.default.pageSizes,
      onePage: false,
      total: 0,
      loading: false,
      list: [],
      teamVisible: false,
      teamData: {},
      banner: '',

      optionTechnicalSupportName: 'page-technicalSupport',
      data: {}
    }
  },
  created() {
    // this.get()
    this.getContent()
    this.getBanner()
  },
  computed: {
    // add
    lang() {
      if(this.$i18n.locale == 'zh-CN') {
        return ''
      }
      return 'en_';
    }
  },
  methods: {
    getContent() {
      this.loading = true
      getOption({
        name: this.optionTechnicalSupportName,
      }).then(res => {
        if(res.data.data) {
          this.data = res.data.data
        }

        this.loading = false
      })
    },

    getBanner() {
      getOption({
        name: this.optionName,
      }).then(res => {
        if(res.data.data) {
          this.banner = res.data.data.technicalSupport
        }
      })
    },
    get() {
      this.loading = true
      getPosts({
        page: this.currentPage,
        pageSize: this.pageSize,
        type: this.type
      }).then(res => {
        this.loading = false
        this.list = res.data.data.list
        this.total = res.data.data.total
      })
    },
    showTeam(data) {
      this.teamVisible = true
      this.teamData = data
    },
    changeType(type) {
      this.type = type
      this.get()
    },
    goto(id) {
      this.$router.push({
        name: 'post',
        params: {
          id: id,
          type: this.type
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.team-pop{
  .el-dialog{
    width: 780px;
  }
}
.active{
  color: #00923F;
  border-bottom: 2px solid #00923F;
}
.technical-tab {
  background: #eeeeee;
  ul{
    displey:-webkit-flex;
    display: flex;
    -webkit-flex-flow:row nowrap; //设置排列方式为横向排列，并且超出元素不换行
    flex-flow:row nowrap;
    overflow-x: auto;
    white-space:nowrap;
    max-width: 1200px;
    margin: auto;
    padding: 0 10px;
    li{
      cursor: pointer;
      display: inline-block;
      height: 50px;
      line-height: 50px;
      margin-right: 60px;
      font-size: 16px;
    }
  }
}
.technical-warp{
  max-width: 1200px;
  margin: auto;
}
.technical-item{
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px #BEBEBE solid;
  width: 100%;
  overflow: auto;
  position: relative;
  cursor: pointer;
  .el-image{
    width: 30%;
    height: 270px;
    float: left;
  }
  .technical-item-right{
    width: 70%;
    float: left;
    .technical-item-content{
      padding: 0 20px;
      .desc{
        margin: 20px 0;
        color: #6D6D6D;
      }
      .time{
        color: #00923F;
        position: absolute;
        left: calc(30% + 20px);
        bottom: 20px;
        font-size: 1.2rem;
      }
    }
  }
}

.team-box{
  width: 100%;
  overflow: auto;
  .research-item{
    width: 25%;
    float: left;
    height: 260px;
    padding-left: 32px;
    box-sizing: border-box;
    position: relative;
    background: #FBFBFB;
    cursor: pointer;
    -webkit-transition: background-color 0.2s linear;
    -moz-transition: background-color 0.2s linear;
    -o-transition: background-color 0.2s linear;
    -webkit-transition: background-color 0.2s linear;
    -ms-transition: background-color 0.2s linear;
    &:hover {
      background: #F2F3F5;
    }
    &:hover .pic .block {
      left: 0px;
      bottom: 0px;
    }
    .mess {
      float: left;
      margin-top: 105px;
      width: 100%;
      .label{
        font-size: 20px;
        line-height: 30px;
        color: #00923F;
        margin-bottom: 10px;
      }
      .job-mess {
        float: left;
        min-width: 160px;
      }
    }
    .pic {
      position: absolute;
      right: 0;
      top: 0;
      img {
        width: 120px;
        height: 120px;
      }
      .block {
        width: 28px;
        height: 28px;
        background-color: #00923F;
        position: absolute;
        left: -14px;
        bottom: -14px;
        -webkit-transition: all 0.2s linear;
        -moz-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        -webkit-transition: all 0.2s linear;
        -ms-transition: all 0.2s linear;
      }
    }
  }
}
.pop-box {
  width: 100%;
  min-height: 520px;
  overflow: auto;
  //position: absolute;
  //left: 50%;
  //top: 50%;
  //transform: translate(-50%, -50%);
  //-webkit-transform: translate(-50%, -50%);
  //-ms-transform: translate(-50%, -50%);
  //-o-transform: translate(-50%, -50%);
  //-moz-transform: translate(-50%, -50%);
  background: #FFFFFF;
  //box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.10);
  //padding: 0px 80px 50px 0px;
  box-sizing: border-box;
  .name {
    font-size: 2rem;
    color: #00923F;
    letter-spacing: 0;
    margin-bottom: 8px;
    margin-top: 48px;
  }
  .team-pic {
    position: absolute;
    left: 0;
    top: 0;
    img {
      width: 200px;
    }
  }
  .mess-box {
    width: 446px;
    float: left;
    margin-left: 256px;
    .t-box {
      //height: 200px;
      border-bottom: 1px solid #E9ECF0;
      margin-bottom: 24px;
      overflow: hidden;
    }
    .desc {
      font-size: 14px;
      color: #5F6464;
      letter-spacing: 0;
      //line-height: 25px;
      overflow-y: auto;
    }
  }
}
/* maxWidth <=768 */
@media screen and (max-width: 768px){
  .team-box {
    .research-item {
      width: 50%;
    }
  }
  .technical-warp{
    margin: 10px;
    .technical-item{
      .el-image{
        width: 40%;
        height: auto;
      }
      .technical-item-right{
        width: 60%;
      }
      h3{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 16px;
      }
      .desc{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 12px;
        margin-top: 6px !important;
      }
      .time{
        font-size: 12px !important;
        left: calc(40% + 20px) !important;
      }
    }
  }
  .team-pop{
    .el-dialog{
      width: 90%;
    }
  }
  .team-pic {
    position: unset !important;
    text-align: center;
  }
  .mess-box{
    width: 100% !important;
    margin-left: 0 !important;
  }
}
</style>